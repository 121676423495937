var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-timeline-item",
        {
          staticClass: "pt-6 mb-n6",
          attrs: {
            icon: "mdi-creation",
            "fill-dot": "",
            color: "info",
            small: ""
          }
        },
        [_vm._v(" Es wurde eine Zusammenfassung generiert. ")]
      ),
      _c(
        "timeline-card",
        {
          attrs: {
            title: "Zusammenfassung"
          }
        },
        [
          _c("v-card-text", {
            domProps: {
              innerHTML: _vm._s(_vm.content)
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }